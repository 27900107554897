/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        buildTime
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          padding: `0`,
          paddingTop: `var(--header-height)`,
        }}
      >
        <main>{children}</main>
        <footer style={{
          padding: `20px 0`, 
          background: `#efefef`, 
          height: `var(--footer-height)`,
          boxSizing: `border-box`
        }}>
          <div className="container" style={{
            fontSize: `0.85em`
          }}>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org">Gatsby</a>.
            Themed by Kun. Last Update: {new Date(data.site.buildTime).toLocaleDateString("en-US")}
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
